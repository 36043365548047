
import { Options, Vue } from 'vue-class-component'
import $ from 'jquery'
import { LogConsole, StorageCommon } from '@/common/HttpCommon'

export default class ThirdPartySearch extends Vue {
  if1Url = ''
  if2Url = ''
  mounted (): void {
    //
    const qt = this.$route.params.qt
    if (qt === '4') {
      this.if1Url = 'https://www.baidu.com/s?wd=' + qt
      this.if2Url = 'https://www.google.com/search?q=' + qt
    } else if (qt === '5') {
      this.if1Url = 'https://www.baidu.com/s?wd=' + qt
      this.if2Url = 'https://cn.bing.com/search?q=' + qt
    } else if (qt === '6') {
      this.if2Url = 'https://www.google.com/search?q=' + qt
      this.if2Url = 'https://cn.bing.com/search?q=' + qt
    }

    $('#container').load('https://www.baidu.com/s?wd=' + qt)
  }
}
